import $ from "jquery";
import ParsleyOptions from "../../components/ParsleyOptions";

export default class LoginForm {

    private $form: JQuery<HTMLFormElement>;
    private $btn: JQuery<HTMLElement>;
    private $alert: JQuery<HTMLElement>;
    private $alertAccountLockedOut: JQuery<HTMLElement>;
    private $labelTimeLockedOut: JQuery<HTMLElement>;
    constructor(element: JQuery) {
        this.$form = (element as JQuery<HTMLFormElement> );

        if (this.$form.length) {
            this.$btn = this.$form.find('.btn-ajax');
            this.$alert = this.$form.find('.incorrect-login');
            this.$alertAccountLockedOut = this.$form.find('.account-locked-login');
            this.$labelTimeLockedOut = this.$form.find('#timeLockedOut');

            this.$form.parsley(new ParsleyOptions());
            this.setEvents();
        }
    }

    private setEvents() {
        this.$form.submit(this.onSubmit);
    }

    private onSubmit = (event: JQuery.Event) => {
        event.preventDefault();
        this.$alert.hide();
        this.$alertAccountLockedOut.hide();
        this.$btn.ajaxButton("start");
        let Email = this.$form.find('#email').val();
        let Password = this.$form.find('#password').val();
        let query = window.location.search.substring(1);
        var params: String = '';
        if (query.length > 0) {
            params = '?' + query;
        }

        // ajax call
        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            contentType: 'application/json;charset=utf-8',
            data: JSON.stringify({ Email, Password }),
            url: '/api/Login' + params,
            headers: { "RequestVerificationToken": (window as any)._requestVerificationToken }
        })
            .done(this.onDone)
            .fail(this.onFail);

        return false;
    };

    private onDone = (response: MoulaAjaxResponse) =>
    {
        if (response.IsSuccess)
        {
            if (response.Data.MfaEnabled && response.Data.PhoneNumber != '') {
                let url = response.Data.ReturnUrl as string || "";
                window.location.href = url;
            } else {

                let url = JSViewBag.ReturnUrl as string || "";
                let isDefaultUrl = url && url.includes('BusinessDetails');
                if ((!url || isDefaultUrl) && response.Data.ReturnUrl && response.Data.ReturnUrl !== "") {
                    // TODO: Handle url query string. Currently: '@Html.Raw(ViewBag.QuerySource)';
                    const win = (window as any);
                    win && win.appInsights && win.appInsights.setAuthenticatedUserContext && win.appInsights.setAuthenticatedUserContext(JSViewBag.QueryId);
                    url = response.Data.ReturnUrl + JSViewBag.QuerySource;
                }
                window.location.href = url;
            }

        } else {

            if (response.Data.IsUserLockedOut) {
                this.$labelTimeLockedOut.text(response.Data.TimeLockedOut);
                this.$alertAccountLockedOut.show();
            } else {
                this.onFail();
            }

            this.$btn.ajaxButton("stop");
        }
    };

    private onFail = () => {
        this.$alert.show();
    };

    private onDoneMfa = (response: MoulaAjaxResponse) => {

        let url = JSViewBag.ReturnUrl as string || "";
        let isDefaultUrl = url && url.includes('BusinessDetails');
        if ((!url || isDefaultUrl) && response.Data.ReturnUrl && response.Data.ReturnUrl !== "") {
            // TODO: Handle url query string. Currently: '@Html.Raw(ViewBag.QuerySource)';
            const win = (window as any);
            win && win.appInsights && win.appInsights.setAuthenticatedUserContext && win.appInsights.setAuthenticatedUserContext(JSViewBag.QueryId);
            url = response.Data.ReturnUrl + JSViewBag.QuerySource;
        }

        window.location.href = '/MfaMobile?returnUrl=' + url;
    };
}