import $ from 'jquery';
import ParsleyOptions from "../../components/ParsleyOptions";

export default class MobForm {

    private $form: JQuery<HTMLFormElement>;
    private $btn: JQuery<HTMLElement>;
    private $invalidAlert: JQuery<HTMLElement>;
    private $mobInput: JQuery<HTMLElement>;
    constructor(element: JQuery) {
        this.$form = (element as JQuery<HTMLFormElement>);

        if (this.$form.length) {
            this.$btn = this.$form.find('.btn-ajax');
            this.$invalidAlert = this.$form.find('.invalidAlert');
            this.$form.parsley(new ParsleyOptions());
            this.$mobInput = this.$form.find('#mob');
            this.setEvents();
        }
    }

    private setEvents() {
        this.$form.submit(this.onSubmit);
        this.$mobInput.on('input', () => {
            if (this.validate() !== 'err') {
                this.$invalidAlert.hide();
                this.$mobInput.removeClass('invalidInput');
            }
        });
    }



    private onSubmit = (event: JQuery.Event) => {
        let PhoneNumber = this.$form.find('#mob').val();

        event.preventDefault();
        this.$invalidAlert.hide();
        this.$mobInput.removeClass('invalidInput')
        this.$btn.ajaxButton("start");
        if (this.validate() === 'err') {
            this.$invalidAlert.show();
            this.$mobInput.addClass('invalidInput')
            this.$btn.ajaxButton("stop");
        } else {
            this.$invalidAlert.hide();
            this.$mobInput.removeClass('invalidInput');
            this.$btn.ajaxButton("start");
            // ajax call
            $.ajax({
                type: 'POST',
                dataType: 'JSON',
                contentType: 'application/json;charset=utf-8',
                data: JSON.stringify({ PhoneNumber }),
                url: '/api/AddMfaMobile',
                headers: { "RequestVerificationToken": (window as any)._requestVerificationToken }
            })
                .done(this.onDone)
                .fail(() => {
                    this.$invalidAlert.show();
                    this.$mobInput.addClass('invalidInput')
                    this.$btn.ajaxButton("stop");
                })
                .always(() => {
                    this.$btn.ajaxButton("stop");
                    //this.$form.find('.form-fields').remove();
                    //this.$btn.remove();
                })
                ;
        }
        return false;
    };

    private onDone = (response: MoulaAjaxResponse) => {
        if (response.IsSuccess) {

            let url = response.Data.ReturnUrl as string || "";
            window.location.href = url;

        } else {
            // handle more failure scenario
            this.$invalidAlert.show();
            this.$mobInput.addClass('invalidInput')
            this.$btn.ajaxButton("stop");
        }
    };

    private onFail = (response: MoulaAjaxResponse) => {
        this.$btn.ajaxButton("stop");
    };

    private validate = () => {
        const $mobInput = this.$mobInput;
        const v = this.$mobInput.val();
        const MOBILE_REGEX = /^04\d{8}$/;
        if (!v) {
            return null;
        }

        let value = v.toString().replace(/[\s\(\)\.\-]/g, ''); // remove common phone number special characters

        if ((value.length !== 10) || isNaN(parseInt(value, 10))) {
            return 'err';
        }
        if (!MOBILE_REGEX.test(value)) {
            return 'err';
        }

        return null;
    }

}