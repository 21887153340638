import $ from 'jquery';
import ParsleyOptions from "../../components/ParsleyOptions";

export default class MfaForm {

    private $form: JQuery<HTMLFormElement>;
    private $btn: JQuery<HTMLElement>;
    private $alert: JQuery<HTMLElement>;
    private $invalidAlert: JQuery<HTMLElement>;
    private $otpInput: JQuery<HTMLElement>;
    private $resendOtpBtn: JQuery<HTMLElement>;
    private $afterOtpDiv: JQuery<HTMLElement>;
    private $resendingOtp: JQuery<HTMLElement>;
    private $labelTimeLockedOut: JQuery<HTMLElement>;
    constructor(element: JQuery) {
        this.$form = (element as JQuery<HTMLFormElement>);

        if (this.$form.length) {
            this.$btn = this.$form.find('.btn-ajax');
            this.$alert = this.$form.find('.alert');
            this.$invalidAlert = this.$form.find('.invalidAlert');
            this.$form.parsley(new ParsleyOptions());
            this.$otpInput = this.$form.find('#otp');
            this.$resendOtpBtn = this.$form.find('#resendOtpBtn');
            this.$afterOtpDiv = this.$form.find('#afterOtpDiv');
            this.$resendingOtp = this.$form.find('#resendingOtp');
            this.$labelTimeLockedOut = this.$form.find('#timeLockedOut');
            this.setEvents();
        }
    }

    private setEvents() {
        this.$form.submit(this.onSubmit);
        this.$resendOtpBtn.on("click", this.resendClick)
    }

    private resendClick = (event: JQuery.Event) =>
    {
        event.preventDefault();
        let channel = 'sms';
        this.$resendOtpBtn.hide()
        this.$resendingOtp.show()
        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            contentType: 'application/json;charset=utf-8',
            data: JSON.stringify({ channel }),
            url: '/api/RequestMfa',
            headers: { "RequestVerificationToken": (window as any)._requestVerificationToken }
        })
            .done(this.onDoneResend)
            .fail(this.onFailResend)
            .always(() => {
                this.$resendingOtp.hide()
            })

        return false;
    }

    private onSubmit = (event: JQuery.Event) => {
        event.preventDefault();
        this.$alert.hide();
        this.$invalidAlert.hide();
        this.$otpInput.removeClass('invalidInput')
        this.$btn.ajaxButton("start");
        let code = this.$form.find('#otp').val();
        let id = JSViewBag.MfaUserId;

        // ajax call
       $.ajax({
           type: 'POST',
           dataType: 'JSON',
           contentType: 'application/json;charset=utf-8',
           data: JSON.stringify({ code, id }),
           url: '/api/VerifyMfa',
           headers: { "RequestVerificationToken": (window as any)._requestVerificationToken }
        })
            .done(this.onDone)
           .fail(() => {
               this.$invalidAlert.show();
               this.$otpInput.addClass('invalidInput')
               this.$btn.ajaxButton("stop");
           })
            .always(() => {
                //this.$form.find('.form-fields').remove();
                //this.$btn.remove();
            })
            ;
        return false;
    };

    private onDone = (response: MoulaAjaxResponse) => {
        if (response.IsSuccess) {
            let url = JSViewBag.ReturnUrl as string || "";
            //let querysrce = JSViewBag.QuerySource as string || "";
            let isDefaultUrl = url && url.includes('BusinessDetails');

            if ((!url || isDefaultUrl) && response.Data.ReturnUrl && response.Data.ReturnUrl !== "") {
                // TODO: Handle url query string. Currently: '@Html.Raw(ViewBag.QuerySource)';
                const win = (window as any);
                win && win.appInsights && win.appInsights.setAuthenticatedUserContext && win.appInsights.setAuthenticatedUserContext(JSViewBag.QueryId);
                url = response.Data.ReturnUrl 
            }

            window.location.href = url;
        } else {
            this.onFail(response);
        }
    };

    private onFail = (response: MoulaAjaxResponse) =>
    {
        //show this when invalid OTP
        this.$invalidAlert.show();
        this.$otpInput.addClass('invalidInput')

        //show this when multiple retries error
        if (response.Data.IsUserLockedOut) {
            this.$labelTimeLockedOut.text(response.Data.TimeLockedOut);
            this.$alert.show();
            setInterval(() => {
                window.location.href = '/Login';
            }, 30000);
        }

        this.$btn.ajaxButton("stop");

        //if there are 6+ invalid OTC, redirect to login and show error div with class account-locked-login in login.cshtml by show() in login-form.component.ts
        /*Response.redirect("/Login");*/
    };

    private onDoneResend = (response: MoulaAjaxResponse) =>
    {
        if (response.IsSuccess) {
           // this.$resendOtpBtn.hide()
            this.$afterOtpDiv.show()
            setInterval(() => {
                this.$afterOtpDiv.hide()
               this.$resendOtpBtn.show()
            }, 60000);

        } else {
            this.onFailResend();
        }
    };

    private onFailResend = () => {
        this.$resendOtpBtn.show()

    };
}